import React, { PropsWithChildren } from 'react';
import styles from './dropdown-card.module.scss';
import cx from 'classnames';

interface Props {
  visible: boolean;
  translatedLeft?: boolean;
}

const DropdownCard = ({ children, visible, translatedLeft }: PropsWithChildren<Props>) => {
  return (
    <div className={cx(styles.dropdownCard, { [styles.visible]: visible, [styles.translatedLeft]: translatedLeft })}>
      {children}
    </div>
  );
};

export default DropdownCard;
