'use client';

import React, { PropsWithChildren } from 'react';
import cx from 'classnames';
import styles from './fixed-navbar-wrapper.module.scss';
import useScrollPosition from '../../../utils/use-scroll-position';
import { useSearchFocusStore } from '../../../app/homepage/search-focus-store';
import { NavbarType } from '../../layout-configuration';

interface Props {
  desktopType: NavbarType;
  mobileType: NavbarType;
  notificationBarVisible: boolean;
  isHomepage?: boolean;
}

/**
 * a wrapper of the navbar,
 * makes it fixed to the top and to appear only after scrolling.
 */
const FixedNavbarWrapper = (props: PropsWithChildren<Props>) => {
  const y = useScrollPosition();
  const type = props.desktopType;
  const show = type === 'sticky' || (type === 'poppingBackOnScroll' && y > 500);
  const { searchBarFocused, setSearchBarFocused } = useSearchFocusStore();

  return (
    <div
      className={cx(styles.focusCatcher, { [styles.notificationBarVisible]: props.notificationBarVisible })}
      onFocus={() => {
        setSearchBarFocused(true);
      }}
    >
      <div
        className={cx(styles.overlay, { [styles.darken]: searchBarFocused })}
        onClick={() => {
          setSearchBarFocused(false);
        }}
      />
      <div
        className={cx(styles.fixedNavbarWrapper, {
          [styles.visible]: show,
          [styles.notificationBarVisible]: props.notificationBarVisible,
          [styles.homepage]: props.isHomepage,
        })}
      >
        <div className={styles.navbarContainer}>{props.children}</div>
      </div>
    </div>
  );
};

export default FixedNavbarWrapper;
