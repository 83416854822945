'use client';

import { FC } from 'react';
import styles from './ptr-disclaimer.module.scss';
import { Icon, IconName, Separator } from '@weski-monorepo/react-components';
import { useOrigin } from '../../../services/origins/useOrigin';
import { FinancialProtection } from '../../../text-map';

const PtrDisclaimer: FC = () => {
  const origin = useOrigin();

  const isUk = origin?.id === 'uk';
  const isEu = origin?.eu;
  const icons: { name: IconName; width: number }[] | null = isUk
    ? [
        { name: 'ABTOT', width: 100 },
        { name: 'ABTA', width: 100 },
        { name: 'ATOL', width: 40 },
      ]
    : isEu
    ? [
        { name: 'SGR', width: 80 },
        { name: 'Calamiteitenfonds', width: 80 },
      ]
    : null;
  const text = isUk ? FinancialProtection.footerDisclaimer.uk : isEu ? FinancialProtection.footerDisclaimer.eu : null;

  return (
    icons &&
    text && (
      <>
        <div className={styles.container}>
          <div className={styles.logosSection} style={{ minWidth: isEu ? 301.5 : 340 }}>
            {icons.map(({ name, width }, index) => (
              <Icon key={`ptr-disclaimer-icon-${index}`} width={width} isMultiColor={true} icon={name} />
            ))}
          </div>
          <div className={styles.textSection}>
            {text.map((paragraph, index) => (
              <p key={`ptr-disclaimer-text-${index}`}>{paragraph}</p>
            ))}
          </div>
        </div>
        <Separator className={styles.separator} />
      </>
    )
  );
};

export default PtrDisclaimer;
