import React, { useCallback } from 'react';
import { Button, Input, Typography } from '@weski-monorepo/react-components';
import { weskiServer } from '../../../api';
import { useAuth } from '../..';
import { useForm } from 'react-hook-form';
import { EmailInput } from '../email-input';
import style from './sign-up-form.module.scss';
import { useOrigin } from '../../../services/origins/useOrigin';

interface Props {
  onSignUpCompleted?: () => void;
  onAnalytics: () => void;
}

interface FormState {
  name: string;
  email: string;
}

async function signUpWithEmail(fullName: string, email: string) {
  await weskiServer.apiClient.post('/users', {
    user: { fullName, email },
    redirectTo: '',
  });
}

export const SignUpForm = ({ onSignUpCompleted, onAnalytics }: Props) => {
  const { refresh: refreshAuthData } = useAuth();
  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitted, isSubmitting },
  } = useForm<FormState>({});

  const onSubmit = useCallback(
    async (values: FormState) => {
      onAnalytics();
      await signUpWithEmail(values.name, values.email);
      await refreshAuthData();
      onSignUpCompleted?.();
    },
    [refreshAuthData, onSignUpCompleted, onAnalytics]
  );

  const origin = useOrigin();
  const termsBaseUrl = origin?.terms_base_url || 'https://terms.weski.com/il/docs';

  return (
    <>
      <Input
        type="text"
        placeholder="Full name"
        {...register('name', {
          required: { value: true, message: 'Please fill your full name' },
        })}
        error={errors.name?.message}
      />
      <EmailInput formFieldName="email" register={register} error={errors.email?.message} />
      <Button
        variant="main"
        text="Continue"
        onClick={handleSubmit(onSubmit)}
        disabled={isSubmitted && !isValid}
        loading={isSubmitting}
      />
      <Typography variant="P100" className={style.agreement}>
        {/* TODO: not always uk? */}
        By signing up you agree to our <a href={`${termsBaseUrl}/terms-of-service`}>T&C&apos;s</a> and{' '}
        <a href={`${termsBaseUrl}/privacy-policy`}>Privacy Policy.</a>
      </Typography>
    </>
  );
};
