'use client';

import styles from './region-picker.module.scss';
import { FC, useEffect, useState } from 'react';
import { Select } from '@weski-monorepo/react-components';
import { useOriginOptions } from '../../../services/origins/useOriginOptions';
import { useOrigin } from '../../../services/origins/useOrigin';
import { useAnalytics } from '../../../services/analytics/useAnalytics';
import { AnalyticsEvents } from '@weski-monorepo/types';

const RegionPicker: FC = () => {
  const originOptions = useOriginOptions();
  const origin = useOrigin();
  const { sendAnalytics } = useAnalytics();
  const [selectedOriginUrl, setSelectedOriginUrl] = useState<string>();

  useEffect(() => {
    if (origin && originOptions) {
      const selectedOptionUrl = originOptions?.find((option) => option.value === origin?.id)?.url;
      setSelectedOriginUrl(selectedOptionUrl);
    }
  }, [origin, originOptions]);

  const changeOriginAnalyticsReport = async (newOriginUrlValue: string) => {
    const previousOriginId = originOptions?.find((option) => option.url === selectedOriginUrl)?.value;
    const selectedOriginId = originOptions?.find((option) => option.url === newOriginUrlValue)?.value;
    void sendAnalytics(AnalyticsEvents.ORIGIN_CHANGE, { value: selectedOriginId, previousValue: previousOriginId });
  };

  const onRegionChange = (newOriginUrlValue: string) => {
    void changeOriginAnalyticsReport(newOriginUrlValue);
    setSelectedOriginUrl(newOriginUrlValue);
    window.location.href = newOriginUrlValue;
  };

  return (
    <Select
      value={selectedOriginUrl}
      width="210px"
      items={(originOptions || []).map((option) => ({ label: option.label, value: option.url }))}
      itemClassName={styles.itemContainer}
      className={styles.pickerContainer}
      onValueChange={onRegionChange}
    />
  );
};

export default RegionPicker;
