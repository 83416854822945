'use client';

import SocialPlatformIcons from '../social-platforms-icons/social-platforms-icons';
import styles from '../footer-links-section.module.scss';
import { LINKS } from '../../../../constants';
import { openIntercom } from '../../../../features/intercom/intercom';

const FooterSupportSection = () => {
  return (
    <>
      <h5>Support</h5>
      <a href={LINKS.helpCenter}>FAQ | Help Center</a>
      <div onClick={openIntercom} className={styles.footerLink}>
        Chat with ski expert
      </div>
      <a href={LINKS.contactEmail}>Contact support by email</a>
      <SocialPlatformIcons />
    </>
  );
};

export default FooterSupportSection;
