'use client';

import { LINKS } from '../../../../constants';
import FooterDomainBasedLink from '../../footer-domain-based-link/footer-domain-based-link';

const FooterAboutSection = () => {
  return (
    <>
      <h5>About</h5>
      <a href={LINKS.about}>About WeSki</a>
      <FooterDomainBasedLink text="WeSki promise" link={LINKS.weskiPromise} />
      <a href={LINKS.careers}>Careers</a>
      <a href={LINKS.pagePrefix.skiGuides}>Ski guides</a>
      <a href={LINKS.trustpilot}>Reviews</a>
    </>
  );
};

export default FooterAboutSection;
