'use client';

import React, { PropsWithChildren, useState } from 'react';
import styles from './menu-option.module.scss';
import DropdownCard from '../dropdown-card/dropdown-card';
import { Icon, IconName } from '@weski-monorepo/react-components';
import { BackgroundColor } from '../../types';
import classNames from 'classnames';
import { useAnalytics } from '../../../../services/analytics/useAnalytics';
import { AnalyticsEvents } from '@weski-monorepo/types';
import { startCase } from 'lodash';

interface Props {
  optionTitle: string;
  backgroundColor?: BackgroundColor;
  isBoldBlue?: boolean;
  iconName?: IconName;
  href?: string;
  translatedLeft?: boolean;
}

const MenuOption = ({
  children,
  optionTitle,
  backgroundColor,
  isBoldBlue,
  iconName,
  href,
  translatedLeft,
}: PropsWithChildren<Props>) => {
  const [visible, setVisible] = useState(false);
  const { sendAnalytics } = useAnalytics();
  const IconColor = backgroundColor === 'black' ? 'white' : 'black';
  return (
    <li
      className={styles.menuOption}
      onMouseLeave={() => {
        setVisible(false);
      }}
      onMouseEnter={() => {
        setVisible(true);
        void sendAnalytics(AnalyticsEvents.OPEN_MENU, { type: `${startCase(optionTitle)} Menu`, location: 'body' });
      }}
    >
      <div className={styles.optionLabelWrapper}>
        {iconName && (
          <Icon
            icon={iconName}
            cursor="pointer"
            color={visible || isBoldBlue ? IconColor : IconColor}
            className={styles.leftIconMargin}
          />
        )}
        <a
          className={classNames({
            [styles.link]: true,
            [styles.highlightedLink]: visible,
            [styles.boldBlue]: isBoldBlue,
          })}
          href={href}
        >
          {optionTitle}
        </a>
        <div className="ArrowRotator">
          <Icon icon="ChevronDown" cursor="pointer" size={2} color={visible ? IconColor : IconColor} />
        </div>
      </div>
      <DropdownCard visible={visible} translatedLeft={translatedLeft}>
        {children}
      </DropdownCard>
    </li>
  );
};

export default MenuOption;
