import { GeneralTaskResponse } from '@weski-monorepo/types';
import { weskiApi } from '../../api';

export const executeGeneralTask = async (taskName: string) => {
  const { data } = await weskiApi.apiClient.get<GeneralTaskResponse>('/admin/execute-weski-server-task', {
    params: { task_name: taskName },
  });

  return { task: taskName, success: data.success, data: data.success ? data?.data : data?.error?.error };
};
