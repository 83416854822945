import { executeGeneralTask } from '../../../services/admin/execute-general-task';
import { ConfirmationModalProps } from '../auth-menu';
import { AdminAction } from './admin-actions-data';
import { Menu } from '@weski-monorepo/react-components';

const DesktopAdminActions = ({
  adminActions,
  setConfirmationModal,
}: {
  adminActions: AdminAction[];
  setConfirmationModal: (cd: ConfirmationModalProps | null) => void;
}) => {
  const handleAdminActionClick = async (adminAction: AdminAction) => {
    const { confirmationPopup, taskName } = adminAction;

    if (confirmationPopup) {
      setConfirmationModal({
        text: confirmationPopup.message,
        onConfirm: async () => {
          return await executeGeneralTask(taskName);
        },
      });
    } else {
      const { task, success } = await executeGeneralTask(taskName);
      alert(`task: ${task} success: ${success}`);
    }
  };

  return (
    <>
      {adminActions.map((adminAction, index) => {
        return (
          <Menu.Item
            key={`admin-action-${index}`}
            onClick={async () => {
              await handleAdminActionClick(adminAction);
            }}
          >
            {adminAction.title}
          </Menu.Item>
        );
      })}
      {adminActions.length > 0 && <Menu.Divider />}
    </>
  );
};

export default DesktopAdminActions;
