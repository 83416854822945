import { FC } from 'react';
import styles from './social-platforms-icons.module.scss';
import { Icon } from '@weski-monorepo/react-components';
import { LINKS } from '../../../../constants';

const SocialPlatformIcons: FC = () => {
  return (
    <div className={styles.socialContainer}>
      <a href={LINKS.facebook} className={styles.link}>
        <Icon className={styles.link} isMultiColor={true} cursor="pointer" icon={'Facebook'} size={2.5} />
      </a>
      <a href={LINKS.instagram}>
        <Icon cursor="pointer" isMultiColor={true} icon={'Instagram'} size={2.5} />
      </a>
    </div>
  );
};

export default SocialPlatformIcons;
