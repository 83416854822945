'use client';

import React, { useState } from 'react';
import cx from 'classnames';
import { Button, Icon, Avatar } from '@weski-monorepo/react-components';
import { useAuth } from '../hooks/useAuth';
import styles from './current-user-button.module.scss';
import AuthMenu from '../auth-menu/auth-menu';
import { MegaMenuData } from '../../features/mega-menu/types';

export const CurrentUserButton = ({
  megaMenuData,
  isHomepage,
}: {
  megaMenuData: MegaMenuData;
  isHomepage?: boolean;
}) => {
  const { currentUser } = useAuth();
  const [shouldShowMobileSideSheet, setShouldShowMobileSideSheet] = useState(false);
  return (
    <AuthMenu
      trigger={
        <Button
          variant="text"
          className={cx(styles.button, { [styles.blackFriday]: isHomepage })}
          onClick={() => {
            setShouldShowMobileSideSheet(true);
          }}
        >
          <div className={styles.buttonContent}>
            <Icon icon="Menu" width={28} cursor="pointer" />
            {currentUser ? (
              <Avatar src={currentUser.avatar} className={styles.avatar} />
            ) : (
              <Icon icon="AvatarFilled" width={28} />
            )}
          </div>
        </Button>
      }
      shouldShowMobileSideSheet={shouldShowMobileSideSheet}
      closeMobileSideSheet={() => {
        setShouldShowMobileSideSheet(false);
      }}
      megaMenuData={megaMenuData}
    />
  );
};
