'use client';

import { LINKS } from '../../../../constants';
import FooterDomainBasedLink from '../../footer-domain-based-link/footer-domain-based-link';

const FooterLegalSection = () => {
  const { termsOfSale, termsOfService, privacy, cancellationPolicy } = LINKS;

  return (
    <>
      <h5>Legal</h5>
      <FooterDomainBasedLink text="Terms of Sale" link={termsOfSale} />
      <FooterDomainBasedLink text="Terms of Service" link={termsOfService} />
      <FooterDomainBasedLink text="Privacy & Cookies" link={privacy} />
      <FooterDomainBasedLink text="Cancellation Policy" link={cancellationPolicy} />
    </>
  );
};

export default FooterLegalSection;
