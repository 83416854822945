'use client';

import { useOrigin } from '../../services/origins/useOrigin';

const prefilledWhatsappMessage = 'Hey team, can you help me find the perfect ski trip?';
export const useWhatsappChat = () => {
  const origin = useOrigin();
  return () =>
    window.open(
      `https://wa.me/${origin?.contact_details.default.whatsapp}?text=${encodeURIComponent(prefilledWhatsappMessage)}`,
      '_blank'
    );
};
