import React from 'react';
import styles from './multi-column-dropdown.module.scss';
import classNames from 'classnames';
import { ColumnList } from '../../types';
import List from '../list/list';
import MenuLink from '../menu-link/menu-link';

interface Props {
  columns: Array<ColumnList>;
  oneWrappingColumn?: boolean;
  largeColumn?: boolean;
}

const MultiColumnDropdown = ({ columns, oneWrappingColumn, largeColumn }: Props) => {
  const columnsToRender = oneWrappingColumn
    ? [
        {
          items: columns
            .map((column) => {
              return [
                { title: column.header?.title || '', iconName: column.header?.iconName, bold: true },
                ...column.items,
              ];
            })
            .flat(),
        },
      ]
    : columns;

  return (
    <div className={styles.multiColumnDropdownContainer}>
      {columnsToRender.map((column, index) => (
        <List key={`column-${index}`} className={styles.wrappingList}>
          {column.header && (
            <li className={classNames(styles.listItem, styles.bold, { [styles.largeColumn]: largeColumn })}>
              {column.header.title}
            </li>
          )}
          {column.items.map((item, index) => (
            <li
              key={`${item.title}-${index}`}
              className={classNames({
                [styles.listItem]: true,
                [styles.bold]: item.bold,
                [styles.largeColumn]: largeColumn,
              })}
              onClick={item.onClick}
            >
              {
                <MenuLink
                  text={item.title}
                  href={item.href}
                  onClick={item.onClick}
                  bold={item.bold}
                  iconName={item.iconName}
                />
              }
            </li>
          ))}
        </List>
      ))}
    </div>
  );
};

export default MultiColumnDropdown;
