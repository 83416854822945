import { useQuery } from '@tanstack/react-query';
import { OriginOption } from '@weski-monorepo/types';
import { weskiApi } from '../../api';

export const useOriginOptions = () => {
  const { data } = useQuery({
    queryKey: ['originOptions'],
    queryFn: fetchOriginOptions,
  });

  return data;
};

const fetchOriginOptions = async (): Promise<OriginOption[]> => {
  const response = await weskiApi.apiClient.get('/origin/options');
  return response?.data?.data;
};
