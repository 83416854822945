'use client';

import { FC, useEffect } from 'react';
import usePageLoadEvent from '../../services/analytics/use-page-load-event';
import { useIntercom } from '../../features/intercom/intercom';
import Cookies from 'js-cookie';
import { DateTime } from 'luxon';
import { sample } from 'lodash';
import { setAnonymousIdCookie } from '../../services/analytics/useAnalytics';
import useWeskiServerSession from '../../utils/use-weski-server-session';
import { useReportWebVitals } from 'next/web-vitals';
import { log } from '../../infra/log';
import { setTestUserFromUrlParams } from '../../utils/test-user';

const ClientInitializer: FC = () => {
  useWeskiServerSession();
  useIntercom();
  usePageLoadEvent();
  useReportWebVitals((metric) => {
    log.debug(`reporting web vitals`, { url: window.location.href, pathname: window.location.pathname, metric });
  });
  useEffect(() => {
    setTestUserFromUrlParams();
    setAnonymousIdCookie();
    forceAbChannel();
    stickToClientVersionUrlParam();
  }, []);

  return null;
};

export default ClientInitializer;

type ChannelName = 'base' | 'base_price_addon_new' | 'base_price_addon_old';

const channels = ['base_price_addon_new', 'base_price_addon_old'];
const activeChannels: ChannelName[] = ['base_price_addon_new', 'base_price_addon_old'];

function forceAbChannel() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const queryParamAbChannel = urlParams.get('ab_channel');

  if (queryParamAbChannel) {
    Cookies.set('ab_channel', queryParamAbChannel!);
    Cookies.set('ab_channel_date', DateTime.now().toUTC().toISO()!);
    return;
  }

  const currentAbChannel = Cookies.get('ab_channel') as ChannelName;
  // Adjust channels to be randomized
  if (!currentAbChannel || !channels.includes(currentAbChannel)) {
    const newAbChannel = sample(activeChannels);
    Cookies.set('ab_channel', newAbChannel!);
    Cookies.set('ab_channel_date', DateTime.now().toUTC().toISO()!);
  }
}

function stickToClientVersionUrlParam() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const clientVersion = urlParams.get('client_version');
  if (clientVersion === 'new' || clientVersion === 'old') {
    Cookies.set('client_version', clientVersion, { expires: 365 });
  }
}
