import React from 'react';
import { UseFormRegister } from 'react-hook-form';
import { Input } from '@weski-monorepo/react-components';

interface Props {
  register: UseFormRegister<any>;
  formFieldName: string;
  error?: string;
  onKeyUp?: React.KeyboardEventHandler<HTMLInputElement>;
}

const EMAIL_REGEX = /\S+@\S+\.\S+/;

export const EmailInput = ({ register, formFieldName, error, onKeyUp = () => {} }: Props) => {
  const badEmailErrorMessage = 'Please enter a valid email address';
  return (
    <Input
      onKeyUp={onKeyUp}
      type="text"
      {...register(formFieldName, {
        required: {
          value: true,
          message: badEmailErrorMessage,
        },
        pattern: {
          value: EMAIL_REGEX,
          message: badEmailErrorMessage,
        },
      })}
      error={error}
      placeholder="Email address"
    />
  );
};
