import { useGlobalPropsContext } from '../../../context/global-context';
import { Domain } from '../../../utils/get-domain';
import { FC } from 'react';

interface FooterDomainBasedLinkProps {
  text: string;
  link: (domain: Domain) => string;
}

const FooterDomainBasedLink: FC<FooterDomainBasedLinkProps> = ({ text, link }) => {
  const { domain } = useGlobalPropsContext();

  return <a href={link(domain!)}>{text}</a>;
};

export default FooterDomainBasedLink;
