'use client';

import { useEffect } from 'react';
import { AnalyticsEvents } from '@weski-monorepo/types';
import { useAnalytics } from './useAnalytics';
import { useGlobalPropsContext } from '../../context/global-context';
import { usePrevious } from '../../utils/use-previous';

const usePageLoadEvent = () => {
  const { sendAnalytics } = useAnalytics();
  const { pathname } = useGlobalPropsContext();
  const previousPathname = usePrevious(pathname);

  useEffect(() => {
    const hasChanged = previousPathname !== pathname;
    if (hasChanged || previousPathname === undefined) {
      // Send the analytics event
      void sendAnalytics(AnalyticsEvents.VISIT_PAGE, {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, previousPathname, sendAnalytics]);
};

export default usePageLoadEvent;
