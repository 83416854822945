'use client';
import React from 'react';
import Link from 'next/link';
import Logo from '../logo/logo';
import styles from './logo-link.module.scss';
import { useSearchFocusStore } from '../../app/homepage/search-focus-store';
import classNames from 'classnames';

const LogoLink = ({ themeLogo, isHomepage }: { themeLogo: string; isHomepage?: boolean }) => {
  const { setSearchBarFocused } = useSearchFocusStore();
  return (
    <Link
      href="/"
      tabIndex={-1}
      onClick={(e) => {
        (document.activeElement as HTMLSpanElement)?.blur();
        setSearchBarFocused(false);
      }}
    >
      <Logo
        size="default"
        themeLogo={themeLogo}
        className={classNames(styles.logo, { [styles.blackFriday]: isHomepage })}
      />
    </Link>
  );
};

export default LogoLink;
