'use client';

import { CdnImage, DesktopOnly, MobileOnly, TrustBox } from '@weski-monorepo/react-components';
import { FC } from 'react';
import styles from './book-with-confidence-section-footer.module.scss';
import FooterDomainBasedLink from '../../footer-domain-based-link/footer-domain-based-link';
import { useOrigin } from '../../../../services/origins/useOrigin';
import { CDN_IMAGE_URLS, LINKS } from '../../../../constants';

const BookWithConfidenceFooterSection: FC = () => {
  const origin = useOrigin();
  return (
    <div className={styles.bookWithConfidenceSection}>
      <DesktopOnly className={styles.bookWithConfidenceLinks}>
        <FooterDomainBasedLink
          text={`Book with ${origin?.min_deposit ?? ''}%, pay the rest later`}
          link={LINKS.bookWithDeposit}
        />
        <FooterDomainBasedLink text="COVID-19 coverage" link={LINKS.fullCashRefund} />
        <FooterDomainBasedLink text="24/7 support during your trip" link={LINKS.support247} />
        <FooterDomainBasedLink text="Lowest price guarantee" link={LINKS.lowestPriceGuarantee} />
      </DesktopOnly>
      <MobileOnly className={styles.bookWithConfidenceLinks}>
        <FooterDomainBasedLink
          text="You are entitled to a full cash refund and 100% financial protection with WeSki Promise"
          link={LINKS.weskiPromise}
        />
        <FooterDomainBasedLink text="Lowest price guarantee on all ski trips" link={LINKS.lowestPriceGuarantee} />
      </MobileOnly>
      <div className={styles.trustPilotContainer}>
        <TrustBox widgetType="miniDesktop" />
      </div>
      <CdnImage src={CDN_IMAGE_URLS.worldSkiAwardBadge} alt={'Award Badge'} width={136} height={42} />
    </div>
  );
};

export default BookWithConfidenceFooterSection;
