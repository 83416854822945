import React, { useState } from 'react';
import { MenuDropDownProps, MenuOption } from '../../types';
import { VerticalRule } from '@weski-monorepo/react-components';
import classNames from 'classnames';
import styles from './double-sided-menu-dropdown.module.scss';
import List from '../list/list';
import MenuLink from '../menu-link/menu-link';
import { useAnalytics } from '../../../../services/analytics/useAnalytics';
import { AnalyticsEvents } from '@weski-monorepo/types';

const getViewAllCountryText = (countryViewAllPrefix: string, country: string) => {
  return `${countryViewAllPrefix} in ${country === 'United States' ? 'the US' : country}`;
};

export const DoubleSidedMenuDropDown = ({ menuOptions, countryViewAllPrefix, menuName }: MenuDropDownProps) => {
  const [hoveredOption, setHoveredOption] = useState<MenuOption>(menuOptions[0]);
  const { sendAnalytics } = useAnalytics();

  return (
    <div className={styles.dropdownBodyFlexContainer}>
      <List>
        {menuOptions.map((option, index) => (
          <li
            className={styles.listItem}
            onMouseEnter={() => {
              setHoveredOption(option);
              void sendAnalytics(AnalyticsEvents.OPEN_MENU, { type: `${menuName}/${option.name}`, location: 'body' });
            }}
            key={index}
            {...(!option.pageUrl ? { cursor: 'default' } : {})}
          >
            <MenuLink
              text={option.name}
              {...(option.pageUrl ? { href: option.pageUrl, onClick: () => {} } : {})}
              iconName={option.iconName}
              withChevron
            />
          </li>
        ))}
      </List>
      <VerticalRule className={styles.verticalRule} />
      <List>
        {menuOptions.map(({ name: optionName, subOptions }, index) => {
          const subOptionsToShow = countryViewAllPrefix ? subOptions.slice(0, 10) : subOptions;
          return (
            <List key={index} hidden={hoveredOption.name !== optionName}>
              {subOptionsToShow.map(({ name: subOptionName, pageUrl }, subIndex) => (
                <li key={subIndex} className={classNames(styles.listItem, styles.largeColumn)}>
                  <MenuLink text={subOptionName} href={pageUrl} onClick={() => {}} />
                </li>
              ))}
              {hoveredOption.pageUrl && subOptions.length >= 10 && countryViewAllPrefix && (
                <li key={`viewAll-${optionName}`} className={classNames(styles.listItem, styles.largeColumn)}>
                  <MenuLink
                    text={getViewAllCountryText(countryViewAllPrefix, hoveredOption.name)}
                    href={hoveredOption.pageUrl}
                    onClick={() => {}}
                    bold
                  />
                </li>
              )}
            </List>
          );
        })}
      </List>
    </div>
  );
};
