import React from 'react';
import { CdnImage } from '@weski-monorepo/react-components';
import { themes } from '../../styles/themes/themes';
import { CDN_IMAGE_URLS } from '../../constants';

type Size = 'default' | 'large';

interface Props {
  size: Size;
  themeLogo: string;
  className?: string;
}

const imgSize = (isDefaultLogo: boolean) => {
  if (isDefaultLogo) {
    return {
      default: { width: 130, height: 26 },
      large: { width: 130, height: 26 },
    };
  }

  return {
    default: { width: 200, height: 21 },
    large: { width: 250, height: 26 },
  };
};

const cloudinarySizeDefault = { width: 130, height: 26 };
const cloudinarySizePartnerLogo = { width: 671, height: 72 };

const Logo = ({ size = 'default', themeLogo, className }: Props) => {
  const isDefaultLogo = themeLogo === themes.default.logoPic || themeLogo === CDN_IMAGE_URLS.weskiLogoWhite;
  return (
    <CdnImage
      color="white"
      src={themeLogo}
      {...imgSize(isDefaultLogo)[size]}
      options={{
        quality: 100,
        format: isDefaultLogo ? 'svg' : 'auto',
        ...(isDefaultLogo ? cloudinarySizeDefault : cloudinarySizePartnerLogo),
      }}
      className={className}
      alt="Weski logo"
    />
  );
};

export default Logo;
