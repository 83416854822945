'use client';

import React from 'react';
import { NotificationBar as ReactComponentsNotificationBar } from '@weski-monorepo/react-components';
import { useDisplayWithCurrency } from '../services/use-display-with-currency';
import { useOrigin } from '../services/origins/useOrigin';
import { NotificationBarType } from '../layout/layout-configuration';
import { useWhatsappChat } from '../features/whatsapp/useWhatsappChat';
import { useGlobalPropsContext } from '../context/global-context';

interface NotificationBarProps {
  variant: NotificationBarType;
}

const NotificationBar = ({ variant }: NotificationBarProps) => {
  const displayWithCurrency = useDisplayWithCurrency();
  const origin = useOrigin();
  const fixedDeposit = origin?.deposit_value_for_new_group;
  const { isBlackFriday } = useGlobalPropsContext();
  const openWhatsappChat = useWhatsappChat();
  return (
    <ReactComponentsNotificationBar
      variant={variant === 'none' ? 'none' : isBlackFriday ? 'blackFridayCoupon' : 'blackFridayGiveaway'}
      fixedDeposit={displayWithCurrency(fixedDeposit)}
      openWhatsappChat={openWhatsappChat}
      currencySymbol={origin?.currency.symbol || ''}
    />
  );
};

export default NotificationBar;
