import React, { PropsWithChildren } from 'react';
import styles from './list.module.scss';
import classNames from 'classnames';

interface Props {
  hidden?: boolean;
  className?: string;
}

const List = ({ children, hidden, className }: PropsWithChildren<Props>) => {
  return (
    <ul
      className={classNames({
        [styles.list]: true,
        [styles.hidden]: !!hidden,
        ...(className ? { [className]: true } : {}),
      })}
    >
      {children}
    </ul>
  );
};

export default List;
