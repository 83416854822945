import React, { useCallback, useState } from 'react';
import { Button, Typography, HorizontalRule, Icon, useIsMobile, Modal } from '@weski-monorepo/react-components';
import { facebook, google } from '../login-methods';
import cx from 'classnames';
import styles from './auth-modal.module.scss';
import { MagicLinkForm } from './magic-link-form/magic-link-form';
import { SignUpForm } from './sign-up-form/sign-up-form';
import { useAnalytics } from '../../services/analytics/useAnalytics';
import { AnalyticsEvents } from '@weski-monorepo/types';

interface Props {
  open: boolean;
  onClose?: () => void;
  initialMode?: 'signup' | 'login';
}

export const AuthModal = ({ open, onClose, initialMode = 'login' }: Props) => {
  const isMobile = useIsMobile();
  const [mode, setMode] = useState(initialMode);
  const { sendAnalytics } = useAnalytics();
  const onSignWithEmailAnalytics = (emailSignMode: 'signup' | 'login') => {
    void sendAnalytics(AnalyticsEvents.SIGN_UP_CLICK, { mode: emailSignMode, type: 'email' });
  };

  const onFacebookClick = () => {
    void sendAnalytics(AnalyticsEvents.SIGN_UP_CLICK, {
      mode,
      type: 'facebook',
    });
    facebook.login();
  };
  const onGoogleClick = () => {
    void sendAnalytics(AnalyticsEvents.SIGN_UP_CLICK, {
      mode,
      type: 'google',
    });
    google.login();
  };

  const onModalClose = useCallback(() => {
    setMode(initialMode);
    onClose?.();
  }, [onClose, initialMode]);

  return (
    <Modal className={styles.modal} open={open} onClose={onModalClose} showCloseButton={isMobile} fullScreenOnMobile>
      <div className={styles.container}>
        <div className={cx(styles.sideImage, { [styles.signUp]: mode === 'signup' })}>
          <img
            src="https://res.cloudinary.com/ht4mr7djk/image/upload/f_auto,q_auto/weski_logo.png"
            alt="WeSki logo"
            className={styles.weskiLogo}
          />
        </div>
        <div className={styles.content}>
          <Typography className={styles.title} variant="H100">
            {mode === 'login' ? 'Login' : 'Sign up'} to continue
          </Typography>
          <Typography className={styles.subtitle} variant="B400">
            {mode === 'login' ? 'Don’t have an account yet?' : 'Already have an account?'}{' '}
            <a
              onClick={() => {
                setMode((mode) => (mode === 'login' ? 'signup' : 'login'));
              }}
            >
              {mode === 'login' ? 'Sign Up' : 'Login'}
            </a>
          </Typography>
          <div className={styles.loginButtonsContainer}>
            <Button variant="main" onClick={onFacebookClick}>
              <div className={styles.authButtonText}>
                <Icon icon="FacebookWhite" />
                Continue with Facebook
              </div>
            </Button>
            <Button
              variant="outlined"
              borderColor="black"
              textColor="black"
              className={styles.googleButton}
              onClick={onGoogleClick}
            >
              <div className={styles.authButtonText}>
                <Icon icon="Google" isMultiColor />
                Continue with Google
              </div>
            </Button>
          </div>

          <div className={styles.or}>
            <HorizontalRule className={styles.hr} />
            <span>OR</span>
            <HorizontalRule className={styles.hr} />
          </div>
          <div className={styles.magicLinkContainer}>
            <Typography className={styles.subtitle} variant="B400">
              Use your email address
            </Typography>
            {mode === 'login' ? (
              <MagicLinkForm
                onAnalytics={() => {
                  onSignWithEmailAnalytics('login');
                }}
              />
            ) : (
              <SignUpForm
                onSignUpCompleted={onClose}
                onAnalytics={() => {
                  onSignWithEmailAnalytics('signup');
                }}
              />
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};
