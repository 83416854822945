'use client';

import React from 'react';
import styles from './auth-menu.module.scss';
import { Accordion, Avatar, HorizontalRule, Icon, Modal } from '@weski-monorepo/react-components';
import { AuthenticatedUser, useAuth } from '../hooks/useAuth';
import MenuButton from '../menu-button/menu-button';
import classNames from 'classnames';
import { ColumnList, MegaMenuData, MenuOption } from '../../features/mega-menu/types';
import HelpMenu from '../../features/mega-menu/components/help-menu/help-menu';
import {
  SKI_ACCOMMODATIONS,
  SKI_AREAS,
  SKI_DEALS,
  SKI_GUIDES,
  SKI_HOLIDAYS,
  SKI_RESORTS,
} from '../../features/mega-menu/mega-menu';
import { startCase } from 'lodash';
import { LINKS } from '../../constants';
import { AdminAction } from './admin-actions/admin-actions-data';
import MobileAdminActions from './admin-actions/mobile-admin-actions';
import { ConfirmationModalProps } from './auth-menu';
import { useSkiDealsEnabled } from '../../features/ski-deals/ski-deals-definitions';

interface MobileAuthMenuProps {
  currentUser: AuthenticatedUser['user'];
  shouldShowMobileSideSheet: boolean;
  closeMobileSideSheet: () => void;
  contactPhone: string;
  data: MegaMenuData;
  onLoginClick: () => void;
  onSignUpClick: () => void;
  onMenuOpen: (type: string) => void;
  onMegaMenuLinkClick: (link: string) => void;
  adminPermittedMenuActions: AdminAction[];
  setConfirmationModal: (confirmation: ConfirmationModalProps | null) => void;
}

const accordionSubItemsColumnType = (
  columnLists: ColumnList[],
  type: string,
  onMenuOpen: (type: string) => void,
  onMegaMenuLinkClick: (link: string) => void
) => {
  return columnLists.map((columnList) => ({
    header: <span className={styles.flexSpan}>{columnList.header?.title}</span>,
    value: `${type}-${columnList.header?.title}`,
    onOpen: () => {
      onMenuOpen(`${type}/${columnList.header?.title}` || `blank ${type} type`);
    },
    content: columnList.items.map((columnItem, index) => (
      <a
        key={`${columnList.header}-${type}-${index}`}
        className={classNames(styles.menuLink, styles.accordionElement)}
        href={columnItem.href}
        onClick={() => {
          onMegaMenuLinkClick(columnItem.href || `blank ${type} href`);
        }}
      >
        {columnItem.title}
      </a>
    )),
  }));
};
const accordionSubItemsMenuOptionType = (
  menuOptions: MenuOption[],
  type: string,
  onMenuOpen: (type: string) => void,
  onMegaMenuLinkClick: (link: string) => void
) => {
  return menuOptions.map((option) => ({
    header: (
      <span className={styles.flexSpan}>
        <Icon icon={option.iconName} isMultiColor />
        {option.name}
      </span>
    ),
    value: `${type}/${option.name}`,
    onOpen: () => {
      onMenuOpen(`${type}/${option.name}` || `blank ${type} country`);
    },
    content: option.subOptions.map((subOption, index) => (
      <a
        key={`${subOption.name}-${type}-${index}`}
        className={classNames(styles.menuLink, styles.accordionElement)}
        href={subOption.pageUrl}
        onClick={() => {
          onMegaMenuLinkClick(subOption.pageUrl);
        }}
      >
        {subOption.name}
      </a>
    )),
  }));
};
const accordionItem = ({
  itemName,
  onMenuOpen,
  onMegaMenuLinkClick,
  link,
  viewAllText,
  menuOptionsData,
  columnListsData,
  linkList,
}: {
  itemName: string;
  onMenuOpen: (type: string) => void;
  onMegaMenuLinkClick: (link: string) => void;
  link: string;
  viewAllText: string;
  menuOptionsData?: MenuOption[];
  columnListsData?: ColumnList[];
  linkList?: ColumnList[];
}) => {
  let items = [] as ReturnType<typeof accordionSubItemsMenuOptionType>;
  if (menuOptionsData) {
    items = accordionSubItemsMenuOptionType(menuOptionsData, itemName, onMenuOpen, onMegaMenuLinkClick);
  }
  if (columnListsData) {
    items = accordionSubItemsColumnType(columnListsData, itemName, onMenuOpen, onMegaMenuLinkClick);
  }

  return {
    header: itemName,
    value: itemName,
    onOpen: () => {
      onMenuOpen(`${startCase(itemName)} Menu`);
    },
    content: (
      <>
        <a
          className={classNames(styles.menuLink, styles.accordionElement)}
          href={link}
          onClick={() => {
            onMegaMenuLinkClick(link);
          }}
        >
          {viewAllText}
        </a>
        {linkList ? (
          linkList[0]?.items?.map((linkItem, index) => {
            return (
              <a
                key={`${itemName}-${index}`}
                className={classNames(styles.menuLink, styles.accordionElement)}
                href={linkItem.href}
                onClick={() => {
                  onMegaMenuLinkClick(linkItem.href || `blank ${itemName} href`);
                }}
              >
                {linkItem.title}
              </a>
            );
          })
        ) : (
          <Accordion items={items} />
        )}
      </>
    ),
  };
};

const MobileAuthMenu = ({
  currentUser,
  shouldShowMobileSideSheet,
  closeMobileSideSheet,
  contactPhone,
  data,
  onLoginClick,
  onSignUpClick,
  onMenuOpen,
  onMegaMenuLinkClick,
  adminPermittedMenuActions,
  setConfirmationModal,
}: MobileAuthMenuProps) => {
  const { logout } = useAuth();
  const skiDealsEnabled = useSkiDealsEnabled();
  return (
    <Modal
      open={shouldShowMobileSideSheet}
      mode="sideSheet"
      onClose={closeMobileSideSheet}
      className={styles.scrollableSideSheet}
    >
      {currentUser && (
        <>
          <div className={styles.user}>
            {currentUser?.avatar && <Avatar className={styles.avatar} src={currentUser?.avatar}></Avatar>}
            <div>
              {currentUser?.first_name} {currentUser?.last_name}
            </div>
          </div>
          <HorizontalRule className={styles.mobileDivider} />
          <MobileAdminActions
            adminActions={adminPermittedMenuActions}
            setConfirmationModal={setConfirmationModal}
            closeMobileSideSheet={closeMobileSideSheet}
          />
        </>
      )}
      <div className={styles.menuButtonsContainer}>
        <MenuButton
          iconName="Heart"
          title="Saved trips"
          description="View and edit all the trips you created on WeSki"
          link={LINKS.pagePrefix.savedTrips}
          onClick={() => {
            onMegaMenuLinkClick(LINKS.pagePrefix.savedTrips);
          }}
        />
        <MenuButton
          iconName="Card"
          title="Manage bookings"
          description="Manage your bookings and see your itinerary details"
          link={LINKS.pagePrefix.manageBookings}
          onClick={() => {
            onMegaMenuLinkClick(LINKS.pagePrefix.manageBookings);
          }}
        />
      </div>
      <HorizontalRule className={styles.mobileDivider} />
      <HelpMenu contactPhone={contactPhone} />
      <HorizontalRule className={styles.mobileDivider} />
      <Accordion
        items={[
          accordionItem({
            itemName: SKI_RESORTS,
            onMenuOpen,
            onMegaMenuLinkClick,
            link: LINKS.pagePrefix.skiResorts,
            viewAllText: 'View all destinations',
            menuOptionsData: data.skiResorts,
          }),
          accordionItem({
            itemName: SKI_AREAS,
            onMenuOpen,
            onMegaMenuLinkClick,
            link: LINKS.pagePrefix.skiAreas,
            viewAllText: 'View all areas',
            menuOptionsData: data.skiAreas,
          }),
          accordionItem({
            itemName: SKI_HOLIDAYS,
            onMenuOpen,
            onMegaMenuLinkClick,
            link: LINKS.pagePrefix.skiHolidays,
            viewAllText: 'View all ski holidays',
            columnListsData: data.skiHolidays,
          }),
          accordionItem({
            itemName: SKI_ACCOMMODATIONS,
            onMenuOpen,
            onMegaMenuLinkClick,
            link: LINKS.pagePrefix.skiAccommodations,
            viewAllText: 'View all ski accommodations',
            columnListsData: data.skiAccommodations,
          }),
          ...(!skiDealsEnabled
            ? []
            : [
                accordionItem({
                  itemName: SKI_DEALS,
                  onMenuOpen,
                  onMegaMenuLinkClick,
                  link: LINKS.pagePrefix.skiDeals,
                  viewAllText: 'View all ski deals',
                  columnListsData: data.skiDeals,
                }),
              ]),
          ...(data.skiGuides.length === 0
            ? []
            : [
                accordionItem({
                  itemName: SKI_GUIDES,
                  onMenuOpen,
                  onMegaMenuLinkClick,
                  link: LINKS.pagePrefix.skiGuides,
                  viewAllText: 'View all guides',
                  linkList: data.skiGuides,
                }),
              ]),
        ]}
      />
      <HorizontalRule className={styles.mobileDivider} />
      <a className={styles.menuLink} href={LINKS.about}>
        About WeSki
      </a>
      <HorizontalRule className={styles.mobileDivider} />
      <div className={styles.menuButtonsContainer}>
        {currentUser ? (
          <a className={styles.menuLink} onClick={logout}>
            Log out
          </a>
        ) : (
          <>
            <a className={styles.menuLink} onClick={onLoginClick}>
              Log In
            </a>
            <a className={styles.menuLink} onClick={onSignUpClick}>
              Sign up
            </a>
          </>
        )}
      </div>
    </Modal>
  );
};

export default MobileAuthMenu;
