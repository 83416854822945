import { ConfirmationModal, Modal } from '@weski-monorepo/react-components';
import { AdminActionResult, ConfirmationModalProps } from '../auth-menu';
import { FC, useState } from 'react';
import styles from '../auth-menu.module.scss';

const AdminConfirmationModal = ({
  confirmationModal,
  setConfirmationModal,
}: {
  confirmationModal: ConfirmationModalProps | null;
  setConfirmationModal: (cd: ConfirmationModalProps | null) => void;
}) => {
  const [adminActionResultModal, setAdminActionResultModal] = useState<AdminActionResult | undefined>();

  const AdminActionResultModel: FC = () => (
    <Modal
      className={styles.adminActionResultModal}
      open={!!adminActionResultModal}
      onClose={() => {
        setAdminActionResultModal(undefined);
      }}
      showCloseButton={false}
    >
      <>
        <div>{`task: ${adminActionResultModal?.task}`}</div>
        <div> {`success: ${adminActionResultModal?.success}`}</div>
      </>
    </Modal>
  );
  return (
    <>
      <ConfirmationModal
        onClose={() => {
          setConfirmationModal(null);
        }}
        open={!!confirmationModal}
        headline={'Are you sure?'}
        description={confirmationModal?.text}
        confirmationButton={{
          text: 'yes',
          onClick: async () => {
            const result = await confirmationModal?.onConfirm();
            setAdminActionResultModal(result);
            setConfirmationModal(null);
          },
          variant: 'main',
        }}
        cancelButton={{ text: 'Oops, no' }}
      />
      <AdminActionResultModel />
    </>
  );
};

export default AdminConfirmationModal;
