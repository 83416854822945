import React from 'react';
import styles from './menu-button.module.scss';
import { Icon, IconName } from '@weski-monorepo/react-components';

interface MenuButtonProps {
  link: string;
  iconName: IconName;
  title: string;
  description: string;
  onClick: React.MouseEventHandler<HTMLAnchorElement>;
}

const MenuButton = ({ link, iconName, title, description, onClick }: MenuButtonProps) => {
  return (
    <a href={link} onClick={onClick} className={styles.menuButtonContainer}>
      <div className={styles.menuButtonTitleContainer}>
        <Icon icon={iconName} color="rgb(0, 15, 51)" />
        <span className={styles.menuButtonTitle}>{title}</span>
      </div>
      <div className={styles.menuButtonDescriptionContainer}>
        <span className={styles.menuButtonDescription}>{description}</span>
      </div>
    </a>
  );
};

export default MenuButton;
