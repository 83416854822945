'use client';

import React from 'react';
import styles from './menu-link.module.scss';
import classNames from 'classnames';
import { Icon, IconName } from '@weski-monorepo/react-components';
import { useAnalytics } from '../../../../services/analytics/useAnalytics';
import { AnalyticsEvents } from '@weski-monorepo/types';
import Link from 'next/link';

interface Props {
  iconName?: IconName;
  withChevron?: boolean;
  lightWeight?: boolean;
  bold?: boolean;
  text: string;
}

const MenuLink = ({
  text,
  iconName,
  withChevron,
  href,
  lightWeight,
  bold,
  onClick: onMenuLinkClick,
}: Props & React.AnchorHTMLAttributes<HTMLAnchorElement>) => {
  const { sendAnalytics } = useAnalytics();

  const commonContainerProps = {
    className: classNames({
      [styles.menuLink]: true,
      [styles.lightWeight]: !!lightWeight,
      [styles.bold]: !!bold,
      [styles.clickable]: href || (onMenuLinkClick && !href),
    }),
    onClick: (e: React.MouseEvent<HTMLAnchorElement>) => {
      if (onMenuLinkClick) onMenuLinkClick(e);
      if (href) void sendAnalytics(AnalyticsEvents.CLICK_SKI_MEGA_MENU_LINK, { link: href });
    },
  };

  const content = (
    <>
      <div className={styles.textWithIconContainer}>
        {iconName && <Icon icon={iconName} isMultiColor cursor="inherit" />}
        <span>{text}</span>
      </div>
      {withChevron && <Icon icon="ChevronRight" cursor="inherit" />}
    </>
  );

  if (href) {
    return (
      <Link {...commonContainerProps} href={href} prefetch={false}>
        {content}
      </Link>
    );
  }

  return <span {...commonContainerProps}>{content}</span>;
};

export default MenuLink;
