'use client';

import React, { MouseEventHandler } from 'react';
import MultiColumnDropdown from '../multi-column-dropdown/multi-column-dropdown';
import { LINKS } from '../../../../../../weski-client/src/constants';
import { useAnalytics } from '../../../../services/analytics/useAnalytics';
import { AnalyticsEvents } from '@weski-monorepo/types';
import { openIntercom } from '../../../intercom/intercom';

export const helpMenuLinks: (
  contactPhone: string,
  onLinkClick: (link: string) => void
) => { title: string; href: string; onClick?: MouseEventHandler<HTMLAnchorElement> }[] = (
  contactPhone,
  onLinkClick
) => [
  {
    title: 'Help center | FAQ',
    href: LINKS.pagePrefix.helpCenter,
    onClick: () => {
      onLinkClick(LINKS.pagePrefix.helpCenter);
    },
  },
  {
    title: 'Chat with us',
    href: undefined as any,
    onClick: (e) => {
      e.preventDefault();
      openIntercom();
    },
  },
  {
    title: 'Submit a request',
    href: LINKS.submitRequest,
    onClick: () => {
      onLinkClick(LINKS.submitRequest);
    },
  },
  {
    title: `Call us: ${contactPhone}`,
    href: `tel:${contactPhone}`,
  },
];

const HelpMenu = ({ contactPhone }: { contactPhone: string }) => {
  const { sendAnalytics } = useAnalytics();
  const onLinkClick = (link: string) => {
    void sendAnalytics(AnalyticsEvents.CLICK_SKI_MEGA_MENU_LINK, { link });
  };
  const items = helpMenuLinks(contactPhone, onLinkClick);

  return (
    <MultiColumnDropdown
      columns={[
        {
          items,
        },
      ]}
    />
  );
};

export default HelpMenu;
