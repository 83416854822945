'use client';

import React from 'react';
import { BackgroundColor, MegaMenuData } from './types';
import MenuOption from './components/menu-option/menu-option';
import styles from './mega-menu.module.scss';
import classNames from 'classnames';
import HelpMenu from './components/help-menu/help-menu';
import { DesktopOnly } from '@weski-monorepo/react-components';
import { DoubleSidedMenuDropDown } from './components/double-sided-menu-dropdown/double-sided-menu-dropdown';
import MultiColumnDropdown from './components/multi-column-dropdown/multi-column-dropdown';
import { LINKS } from '../../constants';
import { hasFeature } from '../feature-flags';

export const SKI_RESORTS = 'Ski resorts';
export const SKI_AREAS = 'Ski areas';
export const SKI_HOLIDAYS = 'Ski holidays';
export const SKI_ACCOMMODATIONS = 'Ski accommodations';
export const SKI_GUIDES = 'Ski guides';
export const SKI_DEALS = 'Ski deals';

const MegaMenu = ({
  megaMenuData,
  backgroundColor,
}: {
  megaMenuData: MegaMenuData;
  backgroundColor?: BackgroundColor;
}) => {
  const origin = megaMenuData.origin;
  const contactPhone = origin?.contact_details.default.phone;
  return (
    <DesktopOnly className={styles.fullWidthContainer}>
      <nav role="navigation" itemScope itemType="https://schema.org/SiteNavigationElement">
        <div className={styles.desktopMegaMenu}>
          <ul className={classNames(styles.megaMenuSection, styles.flexGrow)}>
            <MenuOption optionTitle={SKI_RESORTS} backgroundColor={backgroundColor} href={LINKS.pagePrefix.skiResorts}>
              <DoubleSidedMenuDropDown
                menuOptions={megaMenuData.skiResorts}
                countryViewAllPrefix="View all resorts"
                menuName={SKI_RESORTS}
              />
            </MenuOption>
            <MenuOption optionTitle={SKI_AREAS} backgroundColor={backgroundColor} href={LINKS.pagePrefix.skiAreas}>
              <DoubleSidedMenuDropDown
                menuOptions={megaMenuData.skiAreas}
                countryViewAllPrefix="View all areas"
                menuName={SKI_AREAS}
              />
            </MenuOption>
            <MenuOption
              optionTitle={SKI_HOLIDAYS}
              backgroundColor={backgroundColor}
              href={LINKS.pagePrefix.skiHolidays}
            >
              <MultiColumnDropdown columns={megaMenuData.skiHolidays} />
            </MenuOption>
            <MenuOption
              optionTitle={SKI_ACCOMMODATIONS}
              backgroundColor={backgroundColor}
              href={LINKS.pagePrefix.skiAccommodations}
            >
              <MultiColumnDropdown columns={megaMenuData.skiAccommodations} oneWrappingColumn />
            </MenuOption>
            {megaMenuData.skiGuides.length > 0 && (
              <MenuOption optionTitle={SKI_GUIDES} backgroundColor={backgroundColor} href={LINKS.pagePrefix.skiGuides}>
                <MultiColumnDropdown columns={megaMenuData.skiGuides} largeColumn />
              </MenuOption>
            )}
            {megaMenuData.skiDeals.length > 0 && (
              <MenuOption
                optionTitle={SKI_DEALS}
                backgroundColor={backgroundColor}
                isBoldBlue
                iconName="Sparkles"
                href={LINKS.pagePrefix.skiDeals}
                translatedLeft
              >
                <MultiColumnDropdown
                  largeColumn
                  columns={[
                    megaMenuData.skiDeals[0],
                    megaMenuData.skiDeals[1],
                    {
                      header: { title: megaMenuData.skiDeals[2].header?.title || '' },
                      items: [
                        ...megaMenuData.skiDeals[2].items,
                        { title: megaMenuData.skiDeals[3].header?.title || '', bold: true },
                        ...megaMenuData.skiDeals[3].items,
                      ],
                    },
                    {
                      header: { title: megaMenuData.skiDeals[4].header?.title || '' },
                      items: [
                        ...megaMenuData.skiDeals[4].items,
                        ...(hasFeature('searchByResortPreference')
                          ? [
                              { title: megaMenuData.skiDeals[5].header?.title || '', bold: true },
                              ...megaMenuData.skiDeals[5].items,
                            ]
                          : []),
                      ],
                    },
                  ]}
                />
              </MenuOption>
            )}
          </ul>
          <ul className={styles.megaMenuSection}>
            <MenuOption optionTitle="Help" iconName="QuestionMark" href="/help" backgroundColor={backgroundColor}>
              <HelpMenu contactPhone={contactPhone || ''} />
            </MenuOption>
            <MenuOption optionTitle="Trips" iconName="Heart" href="/trips" backgroundColor={backgroundColor}>
              <MultiColumnDropdown
                columns={[
                  {
                    items: [
                      { title: 'Saved trips', href: LINKS.pagePrefix.savedTrips },
                      { title: 'Manage bookings', href: LINKS.pagePrefix.manageBookings },
                    ],
                  },
                ]}
              />
            </MenuOption>
          </ul>
        </div>
      </nav>
    </DesktopOnly>
  );
};

export default MegaMenu;
