export const FinancialProtection = {
  footerDisclaimer: {
    uk: [
      'WeSki is a trading name operated by WeTrip Ltd. All the flights and flight-inclusive holidays on this website are financially protected by the ATOL scheme. When you pay you will be supplied with an ATOL Certificate. Please ask for it and check to ensure that everything you booked (flights, hotels and other services) is listed on it. Please see our booking conditions for further information or for more information about financial protection and the ATOL Certificate go to: www.caa.co.uk.',
      'Package holidays that do not include travel by air are financially protected via ABTOT.',
    ],
    eu: [
      'Weski Europe BV (Chamber of Commerce registration number 87334100) is a registered participant of SGR. You can verify this on www.sgr.nl/english. Holidays listed on this website are covered by SGR’s guarantee, within the terms of the SGR guarantee scheme. Under SGR’s guarantee, consumers shall have their prepaid travel sum refunded if their counterparty is unable to fulfill its contractual obligations due to financial inability. Insofar as the agreement includes the transport and the destination has already been reached, the return trip will be arranged.',
    ],
  },
  protectionText: 'ABTA & ATOL Protection',
  box: {
    title: 'Full Financial Protection',
    subtitle: 'Your trip is fully covered!',
    description: {
      default: `Book with confidence! Should we be unable to fulfill your trip, rest assured knowing you're eligible for a prompt, full cash refund within 14 days.`,
      uk: `Book with confidence! With ABTA and ATOL safeguards in place, you're fully covered. Should we be unable to fulfill your trip, rest assured knowing you're eligible for a prompt, full cash refund within 14 days.`,
      ie: `Book with confidence! With ANVR and SGR safeguards in place, you're fully covered. Should we be unable to fulfill your trip, rest assured knowing you're eligible for a prompt, full cash refund within 14 days.`,
    },
    learnMore: {
      default: `Learn more about what's covered.`,
    },
  },
  terms: {
    title: `Book and Relax`,
    description: `Book with confidence, knowing that you can cancel your trip, for any reason, with our flexible cancellation policy`,
    disclaimer: `* Please note - refund amounts are calculated from the total price of your trip. Deposits are non-refundable.`,
    firstStep: `Trip reservation date`,
    lastStep: `Trip departure date`,
    checkInDate: `Check-in date`,
    cash: `cash refund`,
    credit: `credit note`,
    daysBefore: `days before departure`,
    noRefundFirstPart: `Less than`,
    noRefundSecondPart: `days to departure - no refund`,
    tripStarts: `Your trip starts`,
    depositNotice: `Deposit is not refundable.`,
    ofTheTripPrice: 'of the trip price.',
  },
};
