import dynamic from 'next/dynamic';
import { weskiServer } from '../../api';
import { useAuth } from '../hooks/useAuth';
const GoogleOneTapField = dynamic(async () => await import('react-google-one-tap-login'), { ssr: false });

const GOOGLE_CLIENT_ID = '249634854651-3t5r4769u9tudsvjf57o5c5jn82mfkh5.apps.googleusercontent.com';

async function loginWithGoogleOneTap(data: any) {
  await weskiServer.apiClient.post('/google_sign_in', { token: data.credential });
}

export const GoogleOneTap = () => {
  const { refresh: refreshAuthData } = useAuth();
  return (
    <GoogleOneTapField
      onError={(e) => {
        console.error(e);
      }}
      googleAccountConfigs={{
        client_id: GOOGLE_CLIENT_ID,
        callback: async (data) => {
          try {
            await loginWithGoogleOneTap(data);
            void refreshAuthData();
          } catch (error) {
            console.error(error);
          }
        },
      }}
    />
  );
};
