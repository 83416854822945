'use client';

import styles from './weski-copyright.module.scss';
import { Typography } from '@weski-monorepo/react-components';
import Logo from '../../logo/logo';

const WeskiCopyright = ({ themeLogo }: { themeLogo: string }) => {
  return (
    <div className={styles.container}>
      <Logo size="large" themeLogo={themeLogo} />
      <Typography variant="B400" className={styles.text}>
        <span>{'Copyright © ' + new Date().getFullYear() + '. '}</span>
        <span>All rights reserved to WeTrip Ltd.</span>
      </Typography>
    </div>
  );
};

export default WeskiCopyright;
