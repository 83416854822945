'use client';

import { useEffect } from 'react';
import { weskiServer } from '../api';
import { log } from '../infra/log';
import { getAllUrlParams } from './url-params';
import { getCookie } from './cookies';

const useWeskiServerSession = () => {
  useEffect(() => {
    const urlParams = getAllUrlParams();
    const fetchSession = async () => {
      try {
        await weskiServer.apiClient.get('/session/create_session', {
          params: { ...urlParams, referrer: document?.referrer },
        });
      } catch (e) {
        log.error('Error creating session token from WeskiServer', e);
      }
    };

    if (!getCookie('has_weski_server_session_id')) {
      void fetchSession();
    }
  }, []);
};

export default useWeskiServerSession;
