import React, { useCallback, useState } from 'react';
import { magicLink } from '../../login-methods';
import { Button, Typography, Modal } from '@weski-monorepo/react-components';
import { useForm } from 'react-hook-form';
import { EmailInput } from '../email-input';
import styles from './magic-link-form.module.scss';

interface Props {
  onAnalytics: () => void;
}

interface FormState {
  email: string;
}

export const MagicLinkForm = ({ onAnalytics }: Props) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitted, isSubmitting },
  } = useForm<FormState>({});

  const [showSubmittedModal, setShowSubmittedModal] = useState(false);

  const onSubmit = useCallback(
    async (values: FormState) => {
      onAnalytics();
      await magicLink.sendMagicLink(values.email);
      setShowSubmittedModal(true);
    },
    [onAnalytics]
  );

  async function submit() {
    if (!isSubmitting) {
      await handleSubmit(onSubmit)();
    }
  }

  return (
    <>
      <EmailInput
        formFieldName="email"
        register={register}
        error={errors.email?.message}
        onKeyUp={(event) => {
          if (event.key === 'Enter') {
            void submit();
          }
        }}
      />
      <Button
        variant="main"
        text="Send me the magic link"
        disabled={isSubmitted && !isValid}
        onClick={async () => {
          await submit();
        }}
        loading={isSubmitting}
      />
      <Modal open={showSubmittedModal} closable={false} className={styles.modal}>
        <div>
          <Typography variant="H200">Please check your email</Typography>
          <Typography variant="P200">We&apos;ve just sent you an email with a one-click link to login.</Typography>
        </div>
        <Button
          variant="main"
          onClick={() => {
            setShowSubmittedModal(false);
          }}
        >
          Ok
        </Button>
      </Modal>
    </>
  );
};
