import React, { useState } from 'react';
import { Avatar, Menu, useIsMobile } from '@weski-monorepo/react-components';
import { AuthModal } from '../auth-modal/auth-modal';
import { useAuth } from '../hooks/useAuth';
import styles from './auth-menu.module.scss';
import MobileAuthMenu from './mobile-auth-menu';
import { GoogleOneTap } from '../login-methods';
import { MegaMenuData } from '../../features/mega-menu/types';
import { useOrigin } from '../../services/origins/useOrigin';
import { LINKS } from '../../constants';
import { useAnalytics } from '../../services/analytics/useAnalytics';
import { AnalyticsEvents } from '@weski-monorepo/types';
import { adminMenuActions } from './admin-actions/admin-actions-data';
import DesktopAdminActions from './admin-actions/desktop-admin-actions';
import AdminConfirmationModal from './admin-actions/admin-action-confirmaion-modal';

interface Props {
  trigger: React.ReactElement;
  shouldShowMobileSideSheet: boolean;
  closeMobileSideSheet: () => void;
  megaMenuData: MegaMenuData;
}

export interface AdminActionResult {
  task: string;
  success: boolean;
}

export interface ConfirmationModalProps {
  text: string;
  onConfirm: () => Promise<AdminActionResult>;
}

const AuthMenu = ({ trigger, shouldShowMobileSideSheet, closeMobileSideSheet, megaMenuData }: Props) => {
  const isMobile = useIsMobile();
  const { isLoggedIn, logout, currentUser, isUserHasPermission } = useAuth();
  const origin = useOrigin();
  const contactPhone = origin?.contact_details.default.phone || '';
  const [shouldShowAuthModal, setShouldShowAuthModal] = useState(false);
  const [authModalMode, setAuthModalMode] = useState<'signup' | 'login'>('login');
  const [confirmationModal, setConfirmationModal] = useState<ConfirmationModalProps | null>(null);
  const { sendAnalytics } = useAnalytics();
  const adminPermittedMenuActions = adminMenuActions.filter(
    ({ permission }) => currentUser?.admin && (!permission || isUserHasPermission(permission))
  );

  const onMenuOpen = (type: string) => {
    void sendAnalytics(AnalyticsEvents.OPEN_MENU, { type, location: 'body' });
  };
  const onMegaMenuLinkClick = (link: string) => {
    void sendAnalytics(AnalyticsEvents.CLICK_SKI_MEGA_MENU_LINK, { link });
  };

  function showAuthModal(mode: 'signup' | 'login') {
    setAuthModalMode(mode);
    setShouldShowAuthModal(true);
  }

  function closeAuthModal() {
    setShouldShowAuthModal(false);
  }

  const guestMenu = () => (
    <>
      <Menu.Item
        onClick={() => {
          showAuthModal('login');
        }}
      >
        Log In
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          showAuthModal('signup');
        }}
      >
        Sign Up
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item href="/about">About WeSki</Menu.Item>
    </>
  );

  const userMenu = () => (
    <>
      <div className={styles.user}>
        {currentUser?.avatar && <Avatar className={styles.avatar} src={currentUser?.avatar}></Avatar>}
        <div>
          {currentUser?.first_name} {currentUser?.last_name}
        </div>
      </div>
      <Menu.Divider />
      <DesktopAdminActions adminActions={adminPermittedMenuActions} setConfirmationModal={setConfirmationModal} />
      <Menu.Item
        href={LINKS.pagePrefix.savedTrips}
        onClick={() => {
          onMegaMenuLinkClick(LINKS.pagePrefix.savedTrips);
        }}
      >
        Saved Trips
      </Menu.Item>
      <Menu.Item
        href={LINKS.pagePrefix.manageBookings}
        onClick={() => {
          onMegaMenuLinkClick(LINKS.pagePrefix.manageBookings);
        }}
      >
        Bookings
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item
        href={LINKS.about}
        onClick={() => {
          onMegaMenuLinkClick(LINKS.about);
        }}
      >
        About WeSki
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item onClick={logout}>Log out</Menu.Item>
    </>
  );

  return (
    <>
      <AdminConfirmationModal confirmationModal={confirmationModal} setConfirmationModal={setConfirmationModal} />
      {isMobile ? (
        <>
          {trigger}
          <MobileAuthMenu
            currentUser={currentUser}
            shouldShowMobileSideSheet={shouldShowMobileSideSheet}
            closeMobileSideSheet={closeMobileSideSheet}
            contactPhone={contactPhone}
            data={megaMenuData}
            onLoginClick={() => {
              showAuthModal('login');
            }}
            onSignUpClick={() => {
              showAuthModal('signup');
            }}
            onMenuOpen={onMenuOpen}
            onMegaMenuLinkClick={onMegaMenuLinkClick}
            adminPermittedMenuActions={adminMenuActions}
            setConfirmationModal={setConfirmationModal}
          />
        </>
      ) : (
        <Menu className={styles.desktopMenu} trigger={trigger} align="end">
          {isLoggedIn ? userMenu() : guestMenu()}
        </Menu>
      )}
      {!isLoggedIn && <GoogleOneTap />}
      {shouldShowAuthModal && (
        <AuthModal
          initialMode={authModalMode}
          open
          onClose={() => {
            closeAuthModal();
          }}
        />
      )}
    </>
  );
};

export default AuthMenu;
