import { CDN_IMAGE_URLS } from '../../constants';
import { VisitorTrafficType } from '../../utils/cookies';

interface ThemeProps {
  logoPic: string;
}

export const themes: Record<VisitorTrafficType, ThemeProps> = {
  default: {
    logoPic: CDN_IMAGE_URLS.weskiLogo,
  },
  'on-the-snow': {
    logoPic: CDN_IMAGE_URLS.weskiWithOnTheSnowLogo,
  },
  j2ski: {
    logoPic: CDN_IMAGE_URLS.weskiWithJ2SkiLogo,
  },
};
