import { AdminPermission } from '@weski-monorepo/types';

export interface AdminAction {
  title: string;
  permission?: AdminPermission;
  confirmationPopup?: {
    message: string;
  };
  taskName: string;
}

export const adminMenuActions: AdminAction[] = [
  {
    title: 'Refresh general settings',
    taskName: 'clear_general_settings_cache',
    permission: 'clear_general_settings_cache',
    confirmationPopup: {
      message: `Are you sure you want to refresh general settings?`,
    },
  },
  {
    title: 'Refresh transportation cache',
    taskName: 'reset_transportation_cache',
    permission: 'reset_transportation_cache',
    confirmationPopup: {
      message: `Are you sure you want to refresh transportation cache?`,
    },
  },
  {
    title: 'Refresh featured trips',
    taskName: 'clear_featured_packages_cache',
    permission: 'clear_featured_packages_cache',
    confirmationPopup: {
      message: `Are you sure you want to refresh featured trips?`,
    },
  },
  {
    title: 'Refresh SEO',
    taskName: 'clear_blog_cache',
    permission: 'clear_blog_cache',
    confirmationPopup: {
      message: `Are you sure you want to refresh SEO?`,
    },
  },
  {
    title: 'Refresh help center',
    taskName: 'clear_help_center_cache',
    permission: 'clear_help_center_cache',
    confirmationPopup: {
      message: `Are you sure you want to refresh help center?`,
    },
  },
  {
    title: 'Refresh promotions & meals',
    taskName: 'clear_promotions_cache',
    permission: 'clear_promotions_cache',
    confirmationPopup: {
      message: `Are you sure you want to refresh promotions & meals?`,
    },
  },
  {
    title: 'Refresh suppliers',
    taskName: 'update_suppliers',
    permission: 'update_suppliers',
    confirmationPopup: {
      message: `Are you sure you want to refresh suppliers?`,
    },
  },
  {
    title: 'Refresh skipass',
    taskName: 'clear_ski_pass_cache',
    permission: 'clear_ski_pass_cache',
    confirmationPopup: {
      message: `Are you sure you want to refresh skipass?`,
    },
  },
  {
    title: 'Payment data sanity check',
    taskName: 'run_sanity_check',
    permission: 'run_sanity_check',
    confirmationPopup: {
      message: `Are you sure you want to run the payment data sanity check?`,
    },
  },
  {
    title: 'Generate seasonal report',
    taskName: 'send_seasonal_report',
    permission: 'send_seasonal_report',
    confirmationPopup: {
      message: `Are you sure you want to generate the seasonal report?`,
    },
  },
  {
    title: 'Reload airtable accommodations',
    taskName: 'update_availpro_accommodation',
    permission: 'update_availpro_accommodation',
    confirmationPopup: {
      message: `Are you sure you want to reload airtable accommodations?`,
    },
  },
  {
    title: 'Reload Arkiane',
    taskName: 'update_arkiane_accommodation',
    permission: 'update_arkiane_accommodation',
    confirmationPopup: {
      message: `Are you sure you want to reload Arkiane?`,
    },
  },
  {
    title: 'Reload HyperGuest',
    taskName: 'update_hyper_guest_accommodation',
    permission: 'update_hyper_guest_accommodation',
    confirmationPopup: {
      message: `Are you sure you want to reload HyperGuest?`,
    },
  },
  {
    title: 'Reset lessons',
    taskName: 'reset_lessons_cache',
    permission: 'reset_lessons_cache',
    confirmationPopup: {
      message: `Are you sure you want to reset lessons?`,
    },
  },
  {
    title: 'Send weekly transfer bookings',
    taskName: 'send_weekly_transfer_bookings',
    permission: 'send_weekly_transfer_bookings',
    confirmationPopup: {
      message: `Are you sure you want to send weekly transfer bookings?`,
    },
  },
  {
    title: 'Transfer shuffle checkup',
    taskName: 'check_cheaper_transfer_alternatives_checkup',
    permission: 'transfer_shuffle',
    confirmationPopup: {
      message: `Are you sure you want to run the transfer shuffle checkup?`,
    },
  },
  {
    title: 'Transfer shuffle - REAL',
    taskName: 'check_cheaper_transfer_alternatives',
    permission: 'transfer_shuffle',
    confirmationPopup: {
      message: `Are you sure you want to run the actual process of rebooking transfers?\nThis will create new, duplicate bookings and you'll need to manually cancel the existing ones. DO NOT RUN without executing the checkup first.`,
    },
  },
];
