import { HorizontalRule } from '@weski-monorepo/react-components';
import { executeGeneralTask } from '../../../services/admin/execute-general-task';
import { AdminAction } from './admin-actions-data';
import styles from '../auth-menu.module.scss';
import { ConfirmationModalProps } from '../auth-menu';

const MobileAdminActions = ({
  adminActions,
  setConfirmationModal,
  closeMobileSideSheet,
}: {
  adminActions: AdminAction[];
  setConfirmationModal: (cd: ConfirmationModalProps | null) => void;
  closeMobileSideSheet: () => void;
}) => {
  const handleAdminActionClick = async ({ confirmationPopup, taskName }: AdminAction) => {
    closeMobileSideSheet();
    if (confirmationPopup) {
      setConfirmationModal({
        text: confirmationPopup.message,
        onConfirm: async () => {
          return await executeGeneralTask(taskName);
        },
      });
    } else {
      const { task, success } = await executeGeneralTask(taskName);
      alert(`task: ${task} success: ${success}`);
    }
  };

  return (
    <>
      <div className={styles.adminActionsMobileContainer}>
        {adminActions.map((adminAction, index) => {
          return (
            <a
              key={`admin-mobile-action-${index}`}
              onClick={async () => {
                await handleAdminActionClick(adminAction);
              }}
            >
              {adminAction.title}
            </a>
          );
        })}
      </div>
      {adminActions.length > 0 && <HorizontalRule className={styles.mobileDivider} />}
    </>
  );
};

export default MobileAdminActions;
